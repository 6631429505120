<template>
    <details ref="accordion" @click="onClick()">
        <summary class="flex gap-[16px] p-[16px] items-center bg-[#F3F4F6] rounded-[4px] cursor-pointer">
            <div class="w-[32px] h-[32px] bg-[#FFF] rounded-full flex items-center justify-center"
                :class="{'rotate-180': isOpen }"
            >
                <img width="20" height="20" src="@assets/images/icons/chevron-down.svg" alt="" class="filter-blue">
            </div>
            <span class="text-2xl text-[#0A0D14] font-medium">
                {{ title }}
            </span>
        </summary>
        <div class="grid grid-cols-[182px_1fr_1fr_1fr] px-[16px] gap-[16px]">
            <slot></slot>
        </div>
    </details>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data: () => ({
        isOpen: false
    }),
    methods: {
        onClick() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>
